define("a24-ember-staffshift-core/services/help-box-service", ["exports", "a24-ember-staffshift-core/services/chat-boxes-service-base"], function (exports, _chatBoxesServiceBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _chatBoxesServiceBase.default.extend({
        bHidden: false,
        iRenderCount: 0,

        remount: function remount() {
            var _this = this;

            this.hideChatBox();
            this.set("bHidden", true);
            Ember.run.next(function () {
                _this.set("bHidden", false);
                _this.set("iRenderCount", _this.get("iRenderCount") + 1);
            });
        }
    });
});