define("a24-ember-candidate-profile/components/timeline-card", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/timeline-card"], function (exports, _didRenderChangesMixin, _timelineCard) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _timelineCard.default,
        objActions: null,
        objData: null,
        classNames: ["timeline-card"],

        _bLoading: true,
        _sHeader: null,
        _sPosition: null,
        _sEmployerName: null,
        _sStatusIcon: null,
        _sStatus: null,
        _sStatusColor: null,
        _objPositionTypes: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),

        init: function init() {
            this._super.apply(this, arguments);
            if (a24Core.isEmpty(this.get("objActions"))) {
                this.set("objActions", {
                    arrActions: []
                });
            }
            this._prepareForDisplay();
        },
        _prepareForDisplay: function _prepareForDisplay() {
            var objData = this.get("objData");

            var sWorkType = null;
            var sPosition = null;
            var sPositionType = null;
            var sJobFamilyName = null;
            var sJobTitleName = null;
            var sEmployerName = null;
            var sEmployerContactNumber = null;
            var sEmployerLocation = null;
            var arrEmployerLocation = [];
            var sResponsibilities = null;
            var sLeavingReason = null;
            var bUnemployed = false;
            var bStudent = false;
            var sStartDate = null;
            var sEndDate = null;
            var sDateUpdated = null;
            var sDateCreated = null;

            if (!a24Core.isEmpty(objData)) {

                if (!a24Core.isEmpty(objData.work_type)) {
                    sWorkType = this.get("_objCandidateProfileEnumsService").getFrontendValue("workTypes", objData.work_type);

                    if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypes.UNEMPLOYED.backend")) {
                        bUnemployed = true;
                    } else if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypes.STUDENT_FULL_TIME.backend")) {
                        bStudent = true;
                    }
                }

                if (!a24Core.isEmpty(objData.position)) {
                    sPosition = objData.position;
                    this.set("_sPosition", objData.position);
                } else {
                    this.set("_sPosition", objData.job_title_name);
                    if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypes.UNEMPLOYED.backend")) {
                        this.set("_sPosition", this.get("_objCandidateProfileEnumsService.workTypes.UNEMPLOYED.frontend"));
                    } else if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypes.STUDENT_FULL_TIME.backend")) {
                        this.set("_sPosition", this.get("_objCandidateProfileEnumsService.workTypes.STUDENT_FULL_TIME.frontend"));
                    }
                }

                if (!a24Core.isEmpty(objData.job_family_name)) {
                    sJobFamilyName = objData.job_family_name;
                }
                if (!a24Core.isEmpty(objData.job_title_name)) {
                    sJobTitleName = objData.job_title_name;
                }

                if (!a24Core.isEmpty(objData.employer_name)) {
                    sEmployerName = objData.employer_name;
                    this.set("_sEmployerName", sEmployerName);
                } else {
                    if (objData.work_type === this.get("_objCandidateProfileEnumsService.workTypes.STUDENT_FULL_TIME.backend")) {
                        this.set("_sEmployerName", objData.institute_name);
                    }
                }

                if (!a24Core.isEmpty(objData.employer_contact_number)) {
                    var sFormattedNumber = objData.objTelInput.formatNumberForDisplay(objData.employer_contact_number);
                    sEmployerContactNumber = a24Core.getSafeStringLink(Ember, sFormattedNumber, objData.employer_contact_number, "phone");
                }

                if (!a24Core.isEmpty(objData.employer_location)) {
                    var sAddressLink = "";
                    var sLocationLabel = "";
                    if (!a24Core.isEmpty(objData.employer_location.address_line1)) {
                        sAddressLink = sAddressLink + objData.employer_location.address_line1;
                        sLocationLabel = objData.employer_location.address_line1;
                    }
                    if (!a24Core.isEmpty(objData.employer_location.address_line2)) {
                        sAddressLink = sAddressLink + " " + objData.employer_location.address_line2;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objData.employer_location.address_line2;
                        } else {
                            arrEmployerLocation.push(objData.employer_location.address_line2);
                        }
                    }
                    if (!a24Core.isEmpty(objData.employer_location.city)) {
                        sAddressLink = sAddressLink + " " + objData.employer_location.city;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objData.employer_location.city;
                        } else {
                            arrEmployerLocation.push(objData.employer_location.city);
                        }
                    }
                    if (!a24Core.isEmpty(objData.employer_location.province)) {
                        sAddressLink = sAddressLink + " " + objData.employer_location.province;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objData.employer_location.address_line2;
                        } else {
                            arrEmployerLocation.push(objData.employer_location.province);
                        }
                    }
                    if (!a24Core.isEmpty(objData.employer_location.country_code)) {
                        var objCountry = this.get("_objEnumsService").getList("country").findBy("sValue", objData.employer_location.country_code.toUpperCase());
                        sAddressLink = sAddressLink + " " + objCountry.sLabel;

                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objCountry.sLabel;
                        } else {
                            arrEmployerLocation.push(this.get("_objEnumsService").getFrontendValue("country", objData.employer_location.country_code.toUpperCase()));
                        }
                    }
                    if (!a24Core.isEmpty(objData.employer_location.post_code)) {
                        if (a24Core.isEmpty(sLocationLabel)) {
                            sLocationLabel = objData.employer_location.post_code;
                        } else {
                            arrEmployerLocation.push(objData.employer_location.post_code);
                        }
                    }

                    sEmployerLocation = a24Core.getSafeStringLink(Ember, sLocationLabel, sAddressLink, "address");
                }

                if (!a24Core.isEmpty(objData.position_type)) {
                    // This is the results from the system route for position types
                    if (!a24Core.isEmpty(objData.arrPositionTypes)) {
                        var objPostionType = objData.arrPositionTypes.find(function (objItem) {
                            return objItem.code === objData.position_type;
                        });

                        sPositionType = objPostionType.name;
                    }
                }

                if (!a24Core.isEmpty(objData.start_date)) {
                    var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objStartDate.setDateFromRest(objData.start_date, true);
                    sStartDate = objStartDate.getDateFormat();
                }

                if (objData.current || a24Core.isEmpty(objData.end_date)) {
                    sEndDate = this.get("_objCandidateProfileStringsService").getString("current");
                } else {
                    var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objEndDate.setDateFromRest(objData.end_date, true);
                    sEndDate = objEndDate.getDateFormat();
                }

                if (!a24Core.isEmpty(objData.updated_at)) {
                    var objDateUpdated = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objDateUpdated.setDateFromRest(objData.updated_at, true);
                    sDateUpdated = objDateUpdated.getDateFormat();
                }

                if (!a24Core.isEmpty(objData.created_at)) {
                    var objDateCreated = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objDateCreated.setDateFromRest(objData.created_at, true);
                    sDateCreated = objDateCreated.getDateFormat();
                }

                if (!a24Core.isEmpty(objData.responsibilities)) {
                    sResponsibilities = objData.responsibilities;
                }

                if (!a24Core.isEmpty(objData.leaving_reason)) {
                    sLeavingReason = objData.leaving_reason;
                }

                this.set("_sHeader", sStartDate + " - " + sEndDate);
            }

            var arrItems = [{
                sLabel: this.get("_objCandidateProfileStringsService").getString("workType"),
                sValue: sWorkType,
                bText: true,
                bHideEmpty: false
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("position"),
                sValue: sPosition,
                bText: true,
                bHideEmpty: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("positionType"),
                sValue: sPositionType,
                bText: true,
                bHideEmpty: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("jobFamilyName"),
                sValue: sJobFamilyName,
                bText: true,
                bHideEmpty: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("jobTitleName"),
                sValue: sJobTitleName,
                bText: true,
                bHideEmpty: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("employerName"),
                sValue: sEmployerName,
                bText: true,
                bHideEmpty: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("employerContactNumber"),
                sValue: sEmployerContactNumber,
                bText: true,
                bHideEmpty: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("employerLocation"),
                sValue: sEmployerLocation,
                bText: true,
                bHideEmpty: true
            }];
            if (!a24Core.isEmpty(arrEmployerLocation)) {
                for (var i = 0; i < arrEmployerLocation.length; i++) {
                    arrItems.push({
                        sLabel: null,
                        sValue: arrEmployerLocation[i],
                        bText: true
                    });
                }
            }
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("startDate"),
                sValue: sStartDate,
                bDate: true,
                bHideEmpty: true
            });
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("endDate"),
                sValue: sEndDate,
                bDate: true,
                bHideEmpty: true
            });
            arrItems.push({
                sLabel: this.get("_objStringsService").getString("dateCreated"),
                sValue: sDateCreated,
                bDate: true,
                bHideEmpty: true
            });
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("dateUpdated"),
                sValue: sDateUpdated,
                bDate: true,
                bHideEmpty: true
            });
            var sResponsibilitiesReasonLabel = this.get("_objCandidateProfileStringsService").getString("responsibilities");
            if (bUnemployed) {
                sResponsibilitiesReasonLabel = this.get("_objCandidateProfileStringsService").getString("unemploymentReason");
            }

            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("institute"),
                sValue: objData.institute_name,
                bText: true,
                bHideEmpty: true
            });

            arrItems.push({
                sLabel: sResponsibilitiesReasonLabel,
                sValue: sResponsibilities,
                bText: true,
                bHideEmpty: bStudent,
                sStyleName: "text-preserve-white-space"
            });

            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("leavingReason"),
                sValue: sLeavingReason,
                bText: true,
                bHideEmpty: true,
                sStyleName: "text-preserve-white-space"
            });

            this.set("arrData", [{
                arrItems: arrItems
            }]);
            // When we add more for skills and references we can just add them here
            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.status)) {
                this.set("_sStatusIcon", "verified_user");
                if (objData.status === "unverified") {
                    this.set("_sStatus", this.get("_objCandidateProfileStringsService").getString("unverified"));
                    this.set("_sStatusColor", "warning-color-text");
                } else {
                    this.set("_sStatus", this.get("_objCandidateProfileStringsService").getString("verified"));
                    this.set("_sStatusColor", "success-color-text");
                }
            }
        }
    });
});