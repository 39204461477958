define("a24-ember-lib/components/saved-search-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/saved-search-component", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _savedSearchComponent, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _savedSearchComponent.default,
        classNames: ["display-inline-block position-relative saved-search-component"],

        objSavedSearchService: null,
        sSavedSearchRef: null,
        sDatagridRoute: null,
        bShowActions: null,
        bShowSaveAction: true,
        sSavedSearchKey: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sReactGatewayUrl: Ember.computed.readOnly("_objEnv.sReactGatewayUrl"),

        _sSavedSearchRemoveId: null,
        _sCustomTabIndex: null,

        _bShowSaveAction: true,
        _bShowAddSavedSearch: false,
        _bShowUnsavedData: false,
        _bPopupLoading: false,
        _bShowDeleteConfirm: false,
        _bPopupDeleteLoading: false,
        _bInitDone: false,

        _arrInitSavedSearch: null,

        _objSelected: null,
        _objGetSavedSearch: null,
        _objCreateSavedSearch: null,
        _objDeleteSavedSearch: null,
        _objOriginalData: null,
        _objSpecialAddPopupAction: null,

        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objUrlTrickeryService: Ember.inject.service("url-trickery"),
        _objToolTipService: Ember.inject.service("tooltip"),

        _bShowReactFilterIframe: false,
        _bReactFilterPopupLoading: true,
        _objReactPopupPostData: null,
        _sIFrameUrl: null,
        _sSelectedSavedSearchId: null,
        _sSelectedSavedSearchName: null,
        _objEditData: null,

        _bLoading: Ember.computed("_bInitDone", function () {
            if (this.get("_bInitDone")) {
                return false;
            }
            return true;
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_sInputId", Ember.guidFor({}));

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", this.get("_sReactGatewayUrl"));
            }

            var objValidationConfig = {};

            objValidationConfig["objChildren.name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddCancel");
                }
            });

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (a24Core.isEmpty(_this.get("bShowActions"))) {
                        _this.set("bShowActions", true);
                    }
                });
            });

            this._getSavedSearch(null);
        },

        setInputBlur: function setInputBlur() {
            var _this2 = this;

            Ember.run.scheduleOnce("afterRender", this, function () {
                var objTextInput = $("#" + _this2.get("_sInputId"));

                _this2.resetFloatingLabel(objTextInput);

                objTextInput.on("blur", function () {
                    _this2.resetFloatingLabel(objTextInput);
                });
            });
        },

        resetFloatingLabel: function resetFloatingLabel(objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },

        _filterSavedSearch: function _filterSavedSearch(sSearchFilter, arrData) {
            var arrFilteredData = [];
            for (var i = 0; i < arrData.length; i++) {
                if (arrData[i].name.toLowerCase().includes(sSearchFilter.toLowerCase())) {
                    arrFilteredData.push(arrData[i]);
                }
            }
            return arrFilteredData;
        },
        _removeSavedSearchData: function _removeSavedSearchData(objRemovedSavedSearchData) {
            var arrSavedSearch = this.get("_arrInitSavedSearch");
            for (var i = 0; i < arrSavedSearch.length; i++) {
                if (arrSavedSearch[i]._id === objRemovedSavedSearchData._id) {
                    this.get("_arrInitSavedSearch").removeAt(i);
                    break;
                }
            }

            this.set("_arrInitSavedSearch", arrSavedSearch);
        },
        _addSavedSearchData: function _addSavedSearchData(objAddedSavedSearchData) {
            this.get("_arrInitSavedSearch").pushObject(objAddedSavedSearchData);
            this.set("_arrInitSavedSearch", this.get("_arrInitSavedSearch").sortBy("name"));
        },

        _getSavedSearch: function _getSavedSearch(funcRefreshCallback) {
            var _this3 = this;

            var objQueryParams = {
                type: {
                    mValue: "shared",
                    bEncode: true
                },
                reference: {
                    mValue: this.get("sSavedSearchRef"),
                    bEncode: true
                },
                deleted: {
                    mValue: false,
                    bEncode: true
                },
                sortBy: {
                    mValue: "name"
                },
                items_per_page: {
                    mValue: 1000
                }
            };

            this.get("objSavedSearchService").getList(this, "_objGetSavedSearch", objQueryParams, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }

                _this3.set("_arrInitSavedSearch", arrData);
                _this3.set("_bInitDone", true);
                _this3.setInputBlur();
                _this3._autoSelectSavedSearch();
                if (!a24Core.isEmpty(funcRefreshCallback)) {
                    funcRefreshCallback();
                }

                if (a24Core.isEmpty(_this3.get("bShowActions"))) {
                    _this3.set("bShowActions", true);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Call the callback with empty
                _this3.set("_arrInitSavedSearch", []);
            }));
        },

        _autoSelectSavedSearch: function _autoSelectSavedSearch(sSavedSearchKey) {
            var _this4 = this;

            var arrSavedSearch = this.get("_arrInitSavedSearch");
            if (a24Core.isEmpty(sSavedSearchKey)) {
                sSavedSearchKey = this.get("sSavedSearchKey");
            }
            var sCurrentUrl = window.location.href.replace(window.location.origin, "");
            if (sCurrentUrl.startsWith("/#/")) {
                sCurrentUrl = sCurrentUrl.substring("/#/".length - 1);
            } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                sCurrentUrl = sCurrentUrl.substring("/emberViews#/".length - 1);
            } else if (this.get("_objEnv").environment === "test") {
                sCurrentUrl = sCurrentUrl.slice(sCurrentUrl.indexOf("/tests/") + 1, sCurrentUrl.length);
            }
            for (var i = 0; i < arrSavedSearch.length; i++) {
                var sUrl = null;
                if (!a24Core.isEmpty(sSavedSearchKey) && arrSavedSearch[i]._id === sSavedSearchKey || sCurrentUrl === arrSavedSearch[i].url) {
                    sUrl = arrSavedSearch[i].url;
                }

                if (!a24Core.isEmpty(sUrl)) {
                    this.set("_sSelectedSavedSearchId", arrSavedSearch[i]._id);
                    this.set("_sSelectedSavedSearchName", arrSavedSearch[i].name);
                    Ember.run.next(function () {
                        var objTextInput = $("#" + _this4.get("_sInputId"));
                        objTextInput.val(arrSavedSearch[i].name).trigger("change");

                        _this4.resetFloatingLabel(objTextInput);
                        _this4.get("changeShowSaveAction")(false);
                    });
                    sCurrentUrl = window.location.href.replace(window.location.origin, "");
                    if (sCurrentUrl.startsWith("/#/") || this.get("_objEnv").environment === "test") {
                        this.get("_objUrlTrickeryService").replaceBrowserUrl(window.location.origin + "/#" + sUrl);
                    } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                        this.get("_objUrlTrickeryService").replaceBrowserUrl(window.location.origin + "/emberViews#" + sUrl);
                    }
                    return;
                }
            }
            Ember.run.next(function () {
                var objTextInput = $("#" + _this4.get("_sInputId"));
                objTextInput.val("").trigger("change");
                _this4.set("_sSelectedSavedSearchId", null);
                _this4.set("_sSelectedSavedSearchName", null);

                _this4.resetFloatingLabel(objTextInput);
                _this4.get("changeShowSaveAction")(true);
            });
        },

        observeSavedSearchKey: Ember.observer("sSavedSearchKey", function () {
            var sSavedSearchKey = this.get("sSavedSearchKey");
            if (!a24Core.isEmpty(sSavedSearchKey)) {
                this._autoSelectSavedSearch(sSavedSearchKey);
            }
        }),

        observeShowAction: Ember.observer("bShowSaveAction", function () {
            var _this5 = this;

            var bShowSaveAction = this.get("bShowSaveAction");
            if (bShowSaveAction) {
                Ember.run.next(function () {
                    var objTextInput = $("#" + _this5.get("_sInputId"));
                    objTextInput.val("").trigger("change");
                    _this5.set("_sSelectedSavedSearchId", null);
                    _this5.set("_sSelectedSavedSearchName", null);

                    _this5.resetFloatingLabel(objTextInput);
                    _this5.get("changeShowSaveAction")(true);
                });
            }
            this.set("_bShowSaveAction", bShowSaveAction);
        }),

        observeSavedSearchName: Ember.observer("_sSelectedSavedSearchName", function () {
            var _this6 = this;

            var sSavedSearchName = this.get("_sSelectedSavedSearchName");
            Ember.run.next(function () {
                var objElement = $(".saved-search-input");
                _this6.get("_objToolTipService").disposeOfTooltip(objElement);
                if (!a24Core.isEmpty(sSavedSearchName)) {
                    _this6.get("_objToolTipService").addTooltip(objElement, sSavedSearchName, "bottom", "a24-tooltip", null);
                }
            });
        }),

        actions: {
            changeShowSaveAction: function changeShowSaveAction(bValue) {
                if (!a24Core.isEmpty(this.get("changeShowSaveAction"))) {
                    this.get("changeShowSaveAction")(bValue);
                }
            },
            showAlertAction: function showAlertAction(sText, sSeverity) {
                if (!a24Core.isEmpty(this.get("showAlertAction"))) {
                    this.get("showAlertAction")(sText, sSeverity);
                }
            },
            saveActionClicked: function saveActionClicked() {
                this.set("_bShowReactFilterIframe", true);
                var objData = {
                    reference: this.get("sSavedSearchRef")
                };
                if (!a24Core.isEmpty(this.get("_objEditData"))) {
                    objData.name = this.get("_objEditData.name");
                    objData.description = this.get("_objEditData.description");
                    objData.savedSearchId = this.get("_objEditData.savedSearchId");
                }
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "datagrid/savedSearch/addEditPopup",
                        data: objData
                    }
                });
            },
            openViewPopupAction: function openViewPopupAction() {
                this.set("_bShowReactFilterIframe", true);
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "datagrid/savedSearch/viewPopup",
                        data: {
                            reference: this.get("sSavedSearchRef"),
                            emberRoute: this.get("sDatagridRoute"),
                            savedSearchData: this.get("_arrInitSavedSearch"),
                            selectedId: this.get("_sSelectedSavedSearchId")
                        }
                    }
                });
            },
            onRecieveReactFilterData: function onRecieveReactFilterData(objData) {
                var _this7 = this;

                if (!a24Core.isEmpty(objData)) {
                    var sCurrentUrl = window.location.href.replace(window.location.origin, "");
                    if (objData.type === "app_ready") {
                        this.set("_bReactFilterPopupLoading", false);
                    } else if (objData.type === "close") {
                        this.set("_bShowReactFilterIframe", false);
                    } else if (objData.type === "getCurrentUrl") {
                        if (sCurrentUrl.startsWith("/#/")) {
                            sCurrentUrl = sCurrentUrl.substring("/#/".length - 1);
                        } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                            sCurrentUrl = sCurrentUrl.substring("/emberViews#/".length - 1);
                        } else if (this.get("_objEnv").environment === "test") {
                            sCurrentUrl = sCurrentUrl.slice(sCurrentUrl.indexOf("/tests/") + 1, sCurrentUrl.length);
                        }

                        sCurrentUrl = sCurrentUrl.replace(/[&?]ember\d{1,}=r-pop/, "");

                        this.set("_objReactPopupPostData", {
                            type: objData.type + "_response",
                            data: {
                                url: sCurrentUrl
                            },
                            requestId: objData.requestId
                        });
                    } else if (objData.type === "refreshSavedSearch") {
                        this.set("_bInitDone", false);
                        // reseting the edit as this will trigger after a save
                        this.set("_objEditData", undefined);

                        var funcRefreshCallback = function funcRefreshCallback() {
                            _this7.set("_objReactPopupPostData", {
                                type: objData.type + "_response",
                                data: {
                                    savedSearchData: _this7.get("_arrInitSavedSearch")
                                },
                                requestId: objData.requestId
                            });
                        };

                        this._getSavedSearch(funcRefreshCallback);
                    } else if (objData.type === "applySavedSearchUrl") {
                        Ember.run.next(function () {
                            var objTextInput = $("#" + _this7.get("_sInputId"));
                            objTextInput.val(objData.data.name).trigger("change");

                            _this7.resetFloatingLabel(objTextInput);
                            _this7.get("changeShowSaveAction")(false);
                        });

                        var _objSavedSearch = this.get("_arrInitSavedSearch").find(function (objSavedSearch) {
                            return objSavedSearch.name === objData.data.name;
                        });

                        this.set("_sSelectedSavedSearchId", _objSavedSearch._id);
                        this.set("_sSelectedSavedSearchName", _objSavedSearch.name);

                        if (objData.data.isEdit) {
                            this.get("showAlertAction")("You can alter the filters and then click the save button next to the saved search input. This will allow you to save the currently applied filters.", "warning");

                            this.set("_objEditData", {
                                name: _objSavedSearch.name,
                                description: _objSavedSearch.description,
                                savedSearchId: _objSavedSearch._id
                            });
                        }

                        sCurrentUrl = window.location.href.replace(window.location.origin, "");
                        if (sCurrentUrl.startsWith("/#/") || this.get("_objEnv").environment === "test") {
                            this.get("_objUrlTrickeryService").replaceBrowserUrl(window.location.origin + "/#" + objData.data.url);
                        } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                            this.get("_objUrlTrickeryService").replaceBrowserUrl(window.location.origin + "/emberViews#" + objData.data.url);
                        }
                        this.set("_bShowReactFilterIframe", false);
                    }
                }
            }
        }
    });
});