define("a24-ember-lib/components/alert-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/alert-component"], function (exports, _didRenderChangesMixin, _alertComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _alertComponent.default,

        classNames: ["alert-component"],

        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),

        sText: null,
        sSeverity: null, // success, warning, danger, info
        bHasClear: false,

        iShowAlertTrigger: null,

        _bShowAlert: false,
        _sIcon: null,
        _sIconColor: null,
        _sClearStyle: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sIconColor", this.get("sSeverity") + "-alert-icon");

            switch (this.get("sSeverity")) {
                case "success":
                    this.set("_sIcon", "task_alt");
                    break;
                case "warning":
                    this.set("_sIcon", "warning_amber");
                    break;
                case "danger":
                    this.set("_sIcon", "error_outline");
                    break;
                case "info":
                    this.set("_sIcon", "info_outline");
                    break;
                default:
                    this.set("_sIcon", "info_outline");
                    break;
            }

            if (a24Core.isEmpty(this.get("sSeverity"))) {
                this.set("sSeverity", "info");
            }

            if (!a24Core.isEmpty(this.get("sText"))) {
                this.set("_bShowAlert", true);
            }
        },

        onShowAlert: Ember.observer("iShowAlertTrigger", function () {
            this.set("_bShowAlert", true);
        }),

        actions: {
            triggerHideAlertButtonAction: function triggerHideAlertButtonAction() {
                this.set("_bShowAlert", false);
            }
        }
    });
});